import RequestAdmin from '@/utils/request'
import config from '@/utils/config'
import { formatDate } from '@/utils/fechas'

export function formatTrabajosPreventivos(trabajos) {
  if (trabajos.length > 0) {
    return trabajos.map(trabajo => trabajo.title).join(', ').toUpperCase()
  }
  return 'No hay trabajos preventivos'
}

export function formatTrabajosCorrectivos(trabajos) {
  if (trabajos.length > 0) {
    return trabajos.map(trabajo => trabajo.title).join(', ').toUpperCase()
  }
  return 'No hay trabajos correctivos'
}

export function sortFnTrabajosPreventivos(x, y, col, rowX, rowY) {
  const aValue = this.formatTrabajosPreventivos(rowX.trabajosPreventivo)
  const bValue = this.formatTrabajosPreventivos(rowY.trabajosPreventivo)

  return aValue.localeCompare(bValue)
}

export function sortFnTrabajosCorrectivos(x, y, col, rowX, rowY) {
  const aValue = this.formatTrabajosCorrectivos(rowX.trabajosCorrectivo)
  const bValue = this.formatTrabajosCorrectivos(rowY.trabajosCorrectivo)

  return aValue.localeCompare(bValue)
}

const url = `${config.URL_BASE}conservacion-gabinetes`
export async function updateCreateGabinete(body, tipo) {
  try {
    const request = new RequestAdmin()
    if (tipo === 1) {
      return request.executePostPutPatch(`${url}/${body.id}`, body, 'PATCH')
    }
    // eslint-disable-next-line no-param-reassign
    delete body.id
    return request.executePostPutPatch(`${url}`, body, 'POST')
  } catch (err) {
    console.error('Error en Crear / Editar Centro de Mando', err)
    return null
  }
}

export async function findGabinete(filter) {
  try {
    const request = new RequestAdmin()
    const urrl = `${url}?filter=${JSON.stringify(filter)}`
    return request.executeGet(urrl)
  } catch (err) {
    console.error('Error en obtener Centros de Mando P&M', err)
    return null
  }
}

// eslint-disable-next-line import/prefer-default-export
export async function getGabinete(id) {
  try {
    if (id === null || id === undefined) return null
    const request = new RequestAdmin()
    return await request.executeGet(`${url}/${id}`)
  } catch (err) {
    console.error('Error en cargar Gabinete', err)
    return null
  }
}

function getClassRotate(item) {
  if (item.isMobile) return 'rotate_image'
  return 'no_rotate_image'
}
function getTrabajosPreventivos(gabinete) {
  if (gabinete.trabajosPreventivo && Array.isArray(gabinete.trabajosPreventivo) && gabinete.trabajosPreventivo.length > 0) {
    return gabinete.trabajosPreventivo.map(trabajo => trabajo.title).join(', ')
  }
  return ''
}

function getTrabajosCorrectivos(gabinete) {
  if (gabinete.trabajosCorrectivo && Array.isArray(gabinete.trabajosCorrectivo) && gabinete.trabajosCorrectivo.length > 0) {
    return gabinete.trabajosCorrectivo.map(trabajo => trabajo.title).join(', ')
  }
  return ''
}

export async function generarReporte(gabinete) {
  const trabajosPreventivos = getTrabajosPreventivos(gabinete)
  const trabajosCorrectivos = getTrabajosCorrectivos(gabinete)
  const html = `
    <!DOCTYPE html>
    <html lang="es">
<head>
  <meta charset="utf-8">
  <meta name="factura" content="width=device-width, initial-scale=1">
  <title>REPORTE DE CONSERVACIÓN DE CENTROS DE MANDO P&M</title>
  <meta charset="utf-8">
</head>
<style>
    @page {
      margin-top: 1.1811in;
      margin-bottom: 0.7874in;
      margin-left: 0.7874in;
      margin-right: 0.7874in;
    }
    .headLogos{
        width: 100%;
        margin-bottom: 5px;
        border-collapse: collapse;
    }
    .letrasLogo{
        background-color: #1f88c2;
        color: white;
        font-family: sans-serif;
    }
    .tabla{
        border-collapse: collapse;
        width: 100%;
        font-family: sans-serif;
    }
    .tituloDatos {
        background-color: #1f88c2;
        color: yellow;
        font-family: sans-serif;
        padding: 3px;
        width: 300px;
    }
    .tituloDetalle {
        background-color: #1f88c2;
        color: yellow;
        font-family: sans-serif;
        padding: 3px;
    }
    th{
        border: 1px solid black;
        font-family: sans-serif;
    }
    td{
        font-family: sans-serif;
        padding-right: 15px;
        padding-left: 15px;
    }
    .Imagenes{
        width: 100%;
        border-collapse: collapse;
    }
    .table {
        border: 1px solid black;
        border-collapse: collapse;
        width: 100%;
        padding-right: 50px;
    }
    .lineaFirma2 {
        border-top: 1px solid black;
        margin-left: auto;
        margin-right: auto;
        width: 40%;
    }
    .lineaFirma3 {
        border-top: 1px solid black;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }
    .lineaFirma{
        border-top: 1px solid black;
        width: 25%;
        display: flex;
        margin-top: 150px;
        font-family: arial,serif;
    }
    .linea {
        position: relative;
        margin-top: 40px;
        width: 100%;
    }

    .linea hr {
        border: none;
        border-top: 1px solid black;
        margin-bottom: 15px;
    }
    .texto {
        text-transform: uppercase;
        text-align: center;
        font-family: arial,serif;
    }
    .centro {
        display: flex;
    }
    .linea span {
        width: 80%;
        position: absolute;
        top: -15px;
        transform: translate(-50%, -50%);
    }
</style>
<body>
<table class="headLogos">
  <tr>
    <th width="66.64%" height="20px" class="letrasLogo"> <center>Reporte de Conservación de Centros de Mando P&M<br>Dirección de Servicios Públicos<br>Municipalidad de Villa Nueva</center></th>
    <th width="33.33%" height="20px">
      <img style="max-width: 180px;" src="https://storage.googleapis.com/apvn-files/imagenes/inspecciones/logo-apvn.jpg" alt="logoapvn"/>
    </th>
  </tr>
</table>
<table style="border-collapse: collapse">
  <tr>
    <th class="tituloDatos">INFORMACIÓN GENERAL</th>
  </tr>
</table>
<table class="table">
  <tr>
    <td style="padding-left: 15px; width: 13%; font-size:10.0pt;">NO. CENTRO DE MANDO P&M</td>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 45%; text-transform: uppercase; font-size:10.0pt">${gabinete.gabinete.poste}</span>
      </div>
    </td>
    <td style="padding-left: 15px; width: 13%; font-size:10.0pt;">BRIGADA</td>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 40%; text-transform: uppercase; font-size:10.0pt">${gabinete.brigada.nombre}</span>
      </div>
    </td>
  </tr>
  <tr>
    <td style="padding-left: 15px; width: 13%; font-size:10.0pt;">LOCALIDAD</td>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 25%; text-align: center; text-transform: uppercase; font-size:10.0pt;">${gabinete.localidad.nombre}</span>
      </div>
    </td>
    <td style="padding-left: 15px; width: 13%; font-size:10.0pt;">FECHA Y HORA</td>
    <td>
      <div class="linea">
        <hr>
        <span style="left: 40%; text-transform: uppercase; font-size:10.0pt">${formatDate(gabinete.fechaCreacion, '/')}</span>
      </div>
    </td>
  </tr>
</table>
<br>
<table style="border-collapse: collapse">
  <tr>
    <th class="tituloDatos">Mantenimiento Preventivo</th>
  </tr>
</table>
<table class="table">
  <tr>
    <td>
      <div class="linea">
        <div style="text-align:justify; text-transform: uppercase; font-size:10.0pt">${trabajosPreventivos}</div>
        <hr>
      </div>
    </td>
  </tr>
</table>
<br>
<table style="border-collapse: collapse">
  <tr>
    <th class="tituloDatos">Mantenimiento Correctivo</th>
  </tr>
</table>
<table class="table">
  <tr>
    <td>
      <div class="linea">
        <div style="text-align:justify; text-transform: uppercase; font-size:10.0pt">${trabajosCorrectivos}</div>
        <hr>
      </div>
    </td>
  </tr>
</table>
<br>
<table style="border-collapse: collapse">
  <tr>
    <th class="tituloDatos">Observaciones</th>
  </tr>
</table>
<table class="table">
  <tr>
    <td>
      <div class="linea">
        <div style="text-align:justify; text-transform: uppercase; font-size:10.0pt">${gabinete.observaciones}</div>
        <hr>
      </div>
    </td>
  </tr>
</table>
<br>
<table class="Imagenes">
  <tr>
    <th class="tituloDatos">Fotografía Panorámica del Antes</th>
    ${gabinete.seguimiento[1] && gabinete.seguimiento[1].url
    ? '<th class="tituloDatos">Fotografía del Mantenimiento Preventivo</th>'
    : ''}
    ${gabinete.seguimiento[2] && gabinete.seguimiento[2].url
    ? '<th class="tituloDatos">Fotografía del Mantenimiento Correctivo</th>'
    : ''}
  </tr>
  <tr>
    <th><img src="${gabinete.seguimiento[0].url}" alt="img_0" width="400" height="400"></th>
    ${gabinete.seguimiento[1] && gabinete.seguimiento[1].url
    ? `<th><img src="${gabinete.seguimiento[1].url}" alt="img_1" width="400" height="400"></th>` : ''}
    ${gabinete.seguimiento[2] && gabinete.seguimiento[2].url
    ? `<th><img src="${gabinete.seguimiento[2].url}" alt="img_2" width="400" height="400"></th>` : ''}
  </tr>
</table>
<br>
<br>
<br>
<br>
<div>
    <hr class="lineaFirma2">
  </div>
  <div class="texto">
    <p><br>ALUMBRADO PÚBLICO DE VILLA NUEVA, S.A.</p>
  </div>
</div>
</body>
</html>
  `

  const body = {
    tamaño: 'A2',
    margenes: {
      top: '2cm',
      right: '2cm',
      bottom: '2cm',
      left: '2cm',
    },
    orientation: 'portrait',
    contenido: html,
  }
  const request = new RequestAdmin()
  const result = await request.executePostPutPatch('https://api.apsistema.com/documentos', body, 'POST')
  return result
}
