<template>
  <div>
    <b-overlay
      :show="show"
    >
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col :cols="editable ? 8 : 12">
              <b-card>
                <b-row>
                  <b-col
                    cols="12"
                    sm="4"
                  >
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Centro de Mando P&M"
                        rules="required"
                      >
                        <h6 class="text-primary">
                          Centro de Mando P&M
                        </h6>
                        <v-select
                          v-model="conservacionGabinete.gabinete"
                          :state="errors.length > 0 ? false:null"
                          disabled
                          label="poste"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    sm="4"
                  >
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Brigada"
                        rules="required"
                      >
                        <h6 class="text-primary">
                          Brigada
                        </h6>
                        <v-select
                          v-model="conservacionGabinete.brigada"
                          :state="errors.length > 0 ? false:null"
                          label="nombre"
                          :options="usuarios"
                          disabled
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    sm="4"
                  >
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Localidad"
                        rules="required"
                      >
                        <h6 class="text-primary">
                          Localidad
                        </h6>
                        <v-select
                          v-model="conservacionGabinete.localidad"
                          label="name"
                          :options="localidades"
                          :disabled="editable"
                          :state="errors.length > 0 ? false:null"
                          @input="filterData"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    sm="12"
                  >
                    <b-form-group>
                      <h6 class="text-primary">
                        Lugar
                      </h6>
                      <validation-provider
                        #default="{ errors }"
                        name="Lugar"
                        rules="required"
                      >
                        <b-form-textarea
                          v-model="conservacionGabinete.lugar"
                          :state="errors.length > 0 ? false:null"
                          rows="2"
                          no-resize
                          :disabled="editable"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="!editable">
                  <b-col
                    cols="12"
                    sm="12"
                  >
                    <h6 class="text-primary">
                      Fotografía Panorámica
                    </h6>
                    <imagen
                      :key="'foto-panoramica'"
                      :leyenda="'foto-panoramica'"
                      @cargar-file="fotografiaPanoramica"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <h2 class="text-primary">
                      Trabajos Realizados
                      <hr>
                    </h2>
                    <br>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    cols="12"
                  >
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Tipo de Trabajo"
                        rules="required"
                      >
                        <h6 class="text-primary">
                          Selecciona los Trabajos a Realizar
                        </h6>
                        <v-select
                          v-model="tipoTrabajo"
                          label="title"
                          multiple
                          :state="errors.length > 0 ? false:null"
                          :options="tiposTrabajo"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    v-if="tipoTrabajo && tipoTrabajo.some(item => item.id === 1)"
                    cols="12"
                    sm="6"
                  >
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Mantenimiento Preventivo"
                        rules="required"
                      >
                        <h6 class="text-primary">
                          Mantenimiento Preventivo
                        </h6>
                        <v-select
                          v-model="conservacionGabinete.trabajosPreventivo"
                          label="nombre"
                          multiple
                          :state="errors.length > 0 ? false:null"
                          :options="trabajosGabinetes.trabajosPreventivo"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-if="tipoTrabajo && tipoTrabajo.some(item => item.id === 2)"
                    cols="12"
                    sm="6"
                  >
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Mantenimiento Correctivo"
                        rules="required"
                      >
                        <h6 class="text-warning">
                          Mantenimiento Correctivo
                        </h6>
                        <v-select
                          v-model="conservacionGabinete.trabajosCorrectivo"
                          label="nombre"
                          multiple
                          :state="errors.length > 0 ? false:null"
                          :options="trabajosGabinetes.trabajosCorrectivo"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="!editable">
                  <b-col :cols="isMobile ? 12 : 6">
                    <b-col
                      v-if="
                        showFotografiaPreventivo = conservacionGabinete.trabajosPreventivo && conservacionGabinete.trabajosPreventivo.length > 0"
                      cols="12"
                      sm="12"
                    >
                      <b-form-group>
                        <h6 class="text-primary">
                          Fotografía del Mantenimiento Preventivo
                        </h6>
                        <imagen
                          :key="'foto-preventiva'"
                          :leyenda="'foto-preventiva'"
                          @cargar-file="fotografiaPreventivo"
                        />
                      </b-form-group>
                    </b-col>
                  </b-col>
                  <b-col :cols="isMobile ? 12 : 6">
                    <b-col
                      v-if="showFotografiaCorrectiva = conservacionGabinete.trabajosCorrectivo && conservacionGabinete.trabajosCorrectivo.length > 0"
                      cols="12"
                      sm="12"
                    >
                      <b-form-group>
                        <h6 class="text-primary">
                          Fotografía del Mantenimiento Correctivo
                        </h6>
                        <imagen
                          :key="'foto-correctiva'"
                          :leyenda="'foto-correctiva'"
                          @cargar-file="fotografiaCorrectiva"
                        />
                      </b-form-group>
                    </b-col>
                  </b-col>
                </b-row>
                <hr>
                <b-row>
                  <b-col cols="12">
                    <validation-provider
                      #default="{ errors }"
                      name="Observaciones"
                      rules="required"
                    >
                      <h6 class="text-primary">
                        Observaciones
                      </h6>
                      <b-form-textarea
                        v-model="conservacionGabinete.observaciones"
                        :state="errors.length > 0 ? false:null"
                        rows="2"
                        no-resize
                        :disabled="editable"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                </b-row>
                <hr>
                <b-row>
                  <!-- BOTONES -->
                  <b-col
                    v-if="editable"
                    cols="12"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mt-2 mr-1"
                      @click="changeEditable"
                    >
                      <feather-icon
                        icon="EditIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">Editar</span>
                    </b-button>
                  </b-col>
                  <b-col
                    v-if="!editable"
                    cols="12"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mt-2 mr-1"
                      @click="validationForm"
                    >
                      <feather-icon
                        icon="SaveIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">{{ getTextButton() }}</span>
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                      type="reset"
                      class="mt-2"
                      @click.prevent="cancel"
                    >
                      <feather-icon
                        icon="SlashIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">Cancelar</span>
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
            <b-col
              v-if="editable && showAdditionalColumn"
              cols="4"
            >
              <b-card>
                <br>
                <h3
                  style="display: flex; justify-content: center;"
                  class="text-primary"
                >
                  Fotografías del Centro de Mando P&M
                </h3>
                <hr>
                <b-carousel
                  id="carousel-caption"
                  controls
                  indicators
                  height="380"
                  :interval="4000"
                >
                  <template v-for="(item, index) in seguimiento">
                    <b-carousel-slide :key="index">
                      <template #img>
                        <img
                          class="d-block img-fluid w-100 img"
                          height="380"
                          :src="item.url"
                          :alt="item.name"
                        >
                      </template>
                    </b-carousel-slide>
                  </template>
                </b-carousel>
              </b-card>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-overlay>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BCard, BFormTextarea, BButton, BOverlay, BCarouselSlide, BCarousel,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Imagen from '@/components/Inspecciones/Imagen.vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  getGabinete,
  updateCreateGabinete,
} from '@/utils/mantenimiento/conservacionGabinetes/conservacionGabinetesUtils'
import { mensajeError, mensajeInformativo } from '@/utils/mensajes'
import { getLocalidades } from '@/utils/localizacion'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getPostesCompany } from '@/utils/postes'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import { getUsersByCompany } from '@/utils/usuarios'
import { saveImagen } from '@/utils/imagenes'
import { getAdminGabinete } from '@/utils/administrador/gabinetes'
import { isMobile, datosBrigada } from '@/utils/funciones'
import { getlistado } from '@/utils/catalogos'

export default {
  components: {
    BCarouselSlide,
    BCarousel,
    BOverlay,
    Imagen,
    BRow,
    BCol,
    BForm,
    BCard,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    BFormGroup,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show: false,
      usuario: JSON.parse(localStorage.getItem('userData')),
      usuarios: [],
      required,
      conservacionGabinete: {
        id: router.currentRoute.params.gabinete,
        fechaCreacion: new Date(),
        seguimiento: [],
        tracking: [
          {
            estado: 'INGRESADA',
            horaFecha: new Date(),
          },
        ],
        brigada: null,
        gabinete: '',
        trabajosPreventivo: [],
        trabajosCorrectivo: [],
        estado: 'INGRESADA',
        lugar: '',
        observaciones: '',
        localidad: null,
        usuarioCreacion: JSON.parse(localStorage.getItem('userData')),
        companyId: JSON.parse(localStorage.getItem('userData')).idCompany,
      },
      tipoTrabajo: null,
      fotoPanoramicaAntes: '',
      fotoPreventivo: '',
      fotoCorrectiva: '',
      tiposTrabajo: [
        {
          id: 1,
          title: 'Mantenimiento Preventivo',
          value: 'preventivo',
        },
        {
          id: 2,
          title: 'Mantenimiento Correctivo',
          value: 'correctivo',
        },
      ],
      trabajosGabinetes: [],
      gabineteId: router.currentRoute.params.gabinete,
      adminGabinete: null,
      gabinete: null,
      brigada: [],
      localidades: [],
      seguimiento: [],
      localization: null,
      showAdditionalColumn: true,
      editable:
          router.currentRoute.params.action !== 'Edit'
          && router.currentRoute.params.action !== 'Create',
      showFotografiaPreventivo: false,
      showFotografiaCorrectiva: false,
    }
  },
  computed: {
    isMobile() {
      return isMobile()
    },
  },
  watch: {
    'conservacionGabinete.trabajoPreventivo': {
      handler(value) {
        this.showFotografiaPreventivo = value && value.some(item => item.id === 1, 2, 3)
      },
      deep: true,
    },
    'conservacionGabinete.trabajosCorrectivo': {
      handler(value) {
        this.showFotografiaCorrectiva = value && value.some(item => item.id === 4, 5)
      },
      deep: true,
    },
  },
  async beforeMount() {
    try {
      this.localidades = await getLocalidades(undefined, this.usuario.company.municipio)
      this.items = []
      if (this.localidades === null) this.localidades = []
      if (this.localidades.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        this.localidad = this.localidades[0]
        await this.loadPostes(this.localidades[0])
      }
    } catch (err) {
      console.error(`Error en cargar ${this.catalogoName}`, err)
      this.localidades = []
      this.items = []
    }
  },
  async created() {
    const gabineteId = this.$route.params.gabinete

    if (this.$route.query.action === 'Create') {
      this.adminGabinete = await getAdminGabinete(gabineteId)
      if (this.adminGabinete && this.adminGabinete.poste) {
        this.conservacionGabinete.gabinete = this.adminGabinete
      }
    }
    this.checkAccessMode()
    const { action } = this.$route.query
    if (action !== 'Create') {
      await this.loadGabinete()
    }
    const trabajosGabinetes = await getlistado(19, 'Trabajos Gabinete', false, this.usuario)
    const trabajosPreventivo = trabajosGabinetes.filter(item => item.tipo === 'preventivo')
    const trabajosCorrectivo = trabajosGabinetes.filter(item => item.tipo === 'correctivo')
    this.trabajosGabinetes = {
      trabajosPreventivo,
      trabajosCorrectivo,
    }
    this.usuarios = await getUsersByCompany(this.usuario.idCompany)
    this.usuarios.map(usuario => {
      // eslint-disable-next-line no-param-reassign
      usuario.nombre = `${usuario.firstName} ${usuario.firstSurname}`
      return usuario
    })
    if (this.conservacionGabinete.usuarioCreacion) {
      this.datosBrigada()
    }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        // eslint-disable-next-line no-return-assign
        pos => (
          this.localization = {
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude,
          }),
        error => {
          console.error('Error Obteniendo Latitud y Longitud!', error)
          this.localization = null
        },
      )
    }
  },
  methods: {
    datosBrigada() {
      if (this.conservacionGabinete.usuarioCreacion) {
        this.conservacionGabinete.brigada = datosBrigada(
          this.conservacionGabinete.usuarioCreacion,
          this.usuarios,
        )
      }
    },
    async loadGabinete() {
      const { gabinete } = this.$route.params
      if (gabinete) {
        this.conservacionGabinete = await getGabinete(gabinete)
        if (!this.conservacionGabinete) {
          console.error('Elemento no encontrado')
        } else {
          this.seguimiento = this.conservacionGabinete.seguimiento || []

          // Asignar tipo de trabajo al modelo tipoTrabajo
          const tipoTrabajoComentarios = this.seguimiento.flatMap(item => item.comentarios)
          this.tipoTrabajo = this.tiposTrabajo.filter(item => tipoTrabajoComentarios.some(comentario => comentario.id === item.id))
        }
      }
    },
    async guardarGabinetes() {
      if (!this.fotoPanoramicaAntes || this.fotoPanoramicaAntes === '') {
        mensajeError('Debe Ingresar una Fotografía Panorámica del Antes!')
        return
      }
      if (this.showFotografiaPreventivo) {
        if (!this.fotoPreventivo || this.fotoPreventivo === '') {
          mensajeError('Debe Ingresar una Fotografía del Trabajo Preventivo!')
          return
        }
      }
      if (this.showFotografiaCorrectiva) {
        if (!this.fotoCorrectiva || this.fotoCorrectiva === '') {
          mensajeError('Debe Ingresar una Fotografía del Funcionamiento!')
          return
        }
      }
      this.show = true

      if (this.localization === null || this.localization === undefined) {
        mensajeError('Error obteniendo geolocalización, intente nuevamente!')
        return
      }

      this.conservacionGabinete.usuarioCreacion = {
        id: this.usuario.id,
        nombre: `${this.usuario.firstName} ${this.usuario.firstSurname}`,
        name: `${this.usuario.firstName} ${this.usuario.firstSurname}`,
        email: this.usuario.email,
        telefono: '',
      }

      const body = { ...this.conservacionGabinete }
      body.seguimiento.tipoTrabajo = this.tipoTrabajo
      let item

      // eslint-disable-next-line prefer-const
      if (this.$route.query.action === 'Create') {
        const newItem = await updateCreateGabinete(body, 2)
        item = { ...newItem }
      } else if (this.$route.query.action === 'Edit') {
        item = body
      }

      const urlBucket = `conservacion-gabinetes/${item.id}`

      if (!item.seguimiento) {
        item.seguimiento = []
      }

      const imagen1 = await saveImagen(urlBucket, this.localization, this.fotoPanoramicaAntes, 'Foto Panorámica Antes', this.tipoTrabajo, this.conservacionGabinete.usuario, isMobile)
      const imagen2 = await saveImagen(urlBucket, this.localization, this.fotoPreventivo, 'Foto Trabajo Preventivo', '', this.conservacionGabinete.usuario, isMobile)
      let imagen3 = null

      if (this.fotoCorrectiva && this.fotoCorrectiva !== '') {
        imagen3 = await saveImagen(urlBucket, this.localization, this.fotoCorrectiva, 'Foto Del Trabajo Correctivo', '', this.conservacionGabinete.usuario, isMobile)
      }

      if (this.$route.query.action === 'Create') {
        if (imagen1) item.seguimiento.push(imagen1)
        if (imagen2) item.seguimiento.push(imagen2)
        if (imagen3) item.seguimiento.push(imagen3)
      } else {
        if (imagen1) item.seguimiento[0] = imagen1
        if (imagen2) item.seguimiento[1] = imagen2
        if (imagen3) item.seguimiento[2] = imagen3
      }

      await updateCreateGabinete(item, 1)

      mensajeInformativo('Guardada', 'Conservación de Centros de Mando P&M Almacenada Perfectamente!')
      this.$router.replace('/conservacion-gabinetes').then(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Conservación Centros de Mando P&M Almacenada!',
            icon: 'CheckIcon',
            variant: 'success',
            text: 'Conservación de Centros de Mando P&M Ingresada Correctamente!',
          },
        })
      })
      this.show = false
    },
    checkAccessMode() {
      const { action } = this.$route.query
      this.editable = action === 'Edit'

      if (!this.editable) {
        this.hideAdditionalColumn()
      }
    },
    hideAdditionalColumn() {
      this.showAdditionalColumn = false
    },
    getTextButton() {
      if (router.currentRoute.params.action === 'Edit') return 'Actualizar'
      return 'Guardar'
    },
    cancel() {
      const { action } = this.$route.query
      if (action === 'Edit' || action === 'Create'
      ) this.$router.push({ path: '/conservacion-gabinetes' })
    },
    changeEditable() {
      router.currentRoute.params.action = 'Edit'
      this.editable = false
    },

    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
            this.guardarGabinetes()
        }
      })
    },
    fotografiaPanoramica(file) {
      this.fotoPanoramicaAntes = file
    },
    fotografiaPreventivo(file) {
      this.fotoPreventivo = file
    },
    fotografiaCorrectiva(file) {
      this.fotoCorrectiva = file
    },
    async loadPostes(filter) {
      if (filter === null) this.items = await getPostesCompany(this.usuario.company, 0)
      else this.items = await getPostesCompany(this.usuario.company, filter.id)
      if (this.items === null) this.items = []
    },
    async filterData(value) {
      await this.loadPostes(value)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
